const filterBlockedParameters = (object, blockedParameters) => {
  if (!blockedParameters) return object
  if (blockedParameters.length === 0) return object

  blockedParameters.forEach((key) => delete object[key])

  return object
}

export default filterBlockedParameters
