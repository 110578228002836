import getLeadCookieInfo from './get-lead-cookie-info'
import urlHelpers from '@/helpers/url-helpers'

const baseUrl = 'https://revelo.com'

const internationalUrlBuilder = () => {
  const leadCookie = getLeadCookieInfo()
  const queryString = urlHelpers.buildQueryString(leadCookie)

  return `${baseUrl}${queryString}`
}

export default internationalUrlBuilder
