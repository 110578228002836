<template>
  <div class="signup-switch-to-english">
    <img
      class="signup-switch-to-english__international-flag"
      data-test="us-flag-icon"
      src="@/assets/icons/flag-us.svg"
    >
    <a
      :href="generatedLink"
      class="signup-switch-to-english__button"
      data-test="redirect-button"
      variant="tertiary"
      target="_blank"
    >
      {{ $t(`${langPath}.redirectButtonText`) }}
    </a>
  </div>
</template>

<script>
import internationalUrlBuilder from '@/helpers/international-url-builder'

export default {
  name: 'SignupSwitchToEnglishLink',
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    generatedLink () {
      return internationalUrlBuilder()
    }
  }

}
</script>

<style lang="scss" scoped>
.signup-switch-to-english {
  align-items: center;
  display: flex;
  margin-top: $base * 38;

  @include breakpoint(md) {
    margin-top: 30px;
  }

  @include breakpoint(lg) {
    align-items: center;
    display: flex;
    margin-top: $base * 38;
  }

  &__international-flag {
    width: $base * 8;
  }

  &__button {
    color: var(--tx-neutral-blank);
    display: inline-block;
    font: var(--tx-title-3);
    font-weight: 400;
    margin-left: $base * 2;
    max-width: 80%;
    text-decoration: underline;
    width: initial;
  }

  &__button:hover {
    color: var(--tx-neutral-blank);
  }
}
</style>
