import Cookies from 'js-cookie'
import filterBlockedParameters from './filter-blocked-parameters'

const BLOCKED_PARAMETERS = ['utm_redirect']

const getLeadCookieInfo = () => {
  const leadCookie = Cookies.get('Lead')

  if (!leadCookie) return {}

  const leadInfo = JSON.parse(leadCookie)

  if (!leadInfo?.parameters) return {}

  return filterBlockedParameters(leadInfo.parameters, BLOCKED_PARAMETERS)
}

export default getLeadCookieInfo
