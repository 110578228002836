export default {
  addParams (url, paramsObj) {
    let qs = ''
    let path = url
    try {
      const { origin, pathname, search } = new URL(url)
      qs = search
      path = `${origin}${pathname}`
    } catch (_) {}

    const params = new URLSearchParams(qs)
    for (const key in paramsObj) {
      params.set(key, paramsObj[key])
    }

    return decodeURIComponent(`${path}?${params.toString()}`)
  },

  buildQueryString (query) {
    if (typeof query !== 'object') return ''
    if (Object.keys(query).length === 0) return ''

    const allParams = new URLSearchParams()

    Object
      .entries(query)
      .forEach(([key, value]) => allParams.append(key, value))

    return `?${allParams.toString()}`
  }
}
